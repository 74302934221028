import React from "react";
import Head from "./head";
import Navbar from "./navbar";
import Footer from "./footer";
import SocialLinks from "./sociallinks";

const Layout = ({ placeholder, hideFooter, children }) => {
    return (
        <React.Fragment>
            <Head />
            <Navbar
                placeholder={placeholder === undefined ? true : placeholder}
            />
            <div className="wrapper">{children}</div>
            
            <div className="social-buttons">
                <SocialLinks />
            </div>
            { !hideFooter ? <Footer /> : null}
        </React.Fragment>
    );
};

export default Layout;
